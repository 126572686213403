var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded && !_vm.selectedProductConfirmed
    ? _c(
        "b-container",
        { staticClass: "container-fluid", attrs: { id: "product-slide" } },
        [
          _c("slide-heading", { attrs: { heading: _vm.currentSlide.heading } }),
          _c("slide-image-and-description", {
            attrs: { slide: _vm.currentSlide },
          }),
          _vm.slideProducts
            ? _c(
                "b-row",
                [
                  _c(
                    "b-card-group",
                    {
                      class: { horizontal: _vm.horizontal },
                      attrs: { deck: "" },
                    },
                    _vm._l(_vm.slideProducts, function (slideProduct) {
                      return _c("product-card", {
                        key: slideProduct.id,
                        attrs: {
                          "slide-product": slideProduct,
                          "slide-jurisdiction": _vm.jurisdiction,
                        },
                        on: {
                          chosen: function ($event) {
                            return _vm.chooseProductHandler(slideProduct)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _c("ct-centered-spinner", [
                _vm._v("\n    loading products...\n  "),
              ]),
          _vm.noDiyProducts
            ? _c("div", [
                _c(
                  "span",
                  {
                    staticClass: "processing-filing-link",
                    on: { click: _vm.goToProcessingFilingSlide },
                  },
                  [
                    _vm._v(
                      "\n      I will file this online directly with the state myself.\n    "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c("product-options-confirm-modal", {
            attrs: { bus: _vm.bus, loading: !_vm.loaded },
            on: {
              productAdded: _vm.productAdded,
              slideProductAdded: _vm.slideProductAdded,
            },
          }),
          _c("stageline-alert-modal", {
            attrs: { message: _vm.stagelineAlertMessage },
          }),
        ],
        1
      )
    : _c("ct-centered-spinner", [_vm._v("\n  Loading products...\n")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }